<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 内容 -->
    <div class="content">
      <div class="region">
        <h3>招募地区:</h3>
        <p v-for="item in jobDate" :key="item.id">
        <p :class="['p_1', { 'p_Btn': item.id == id }]" @click="pBtn(item.id)">{{ item.name }}</p>
        <!-- <el-button type="primary" round plain> </el-button> -->
         </p>
      </div>
      <div class="job">
        <h3>招募职位:</h3>
        <div class="info" v-for="item in engineerList" :key="item.id" @click="IdBtn(item.id)">
          <div class="info_1">{{ item.name }}</div>
          <div class="info_2">
            <el-icon>
              <component :is="item.id == dataId ? 'Minus' : 'Plus'"></component>
              <!-- <Plus v-if='item.id!=dataId'/>
              <SemiSelect v-else  @click="SemiSelectBtn"/> -->
            </el-icon>
          </div>
          <div class="info_3" v-show="item.id == dataId">
            <h3>岗位职责</h3>
            <p v-for="e in item.child_1" :key="e.id">{{ e.title }}</p>
            <h3>{{ item.requirement }}</h3>
            <p v-for="e in item.child_2" :key="e.id">{{ e.title }}</p>
            <h3>{{ item.recruitment }}</h3>
            <p>{{ item.headcount }}人</p>
            <h3>{{ item.location }}</h3>
            <div style="width:100%;text-align: left;">
              <span v-for="(e,index) in item.locationList" :key="e.id"
                style="text-align:left;display: inline-block;margin-left: 0;">{{ index == (item.locationList.length-1) ? e.title:e.title+'、'}}</span>
            </div>
            <h3>联系方式</h3>
            <p>{{item.Email}}</p>
            <a class="p_1" :href="email">投递简历</a>
          </div>
        </div>

      </div>
    </div>

    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/banner.png" class="images">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="content_center">
      <p v-for="item in jobDate" :key="item.id" @click="pBtn(item.id)" :class="[{ 'p_move': item.id == id }]">
        {{ item.name }}</p>
      <div class="info" v-for="item in engineerList" :key="item.id" @click="IdBtn(item.id)">
        <span class="span_1">{{ item.name }}</span>
        <span class="span_2">
          <el-icon>
            <component :is="item.id == dataId ? 'Minus' : 'Plus'"></component>
            <!-- <Plus v-if='item.id!=dataId'/>
                    <SemiSelect v-else  @click="SemiSelectBtn"/> -->
          </el-icon>
        </span>
        <div class="span_3" v-show="item.id == dataId">
          <h3>岗位职责</h3>
          <p style="width: 100%;" v-for="e in item.child_1" :key="e.id">{{ e.title }}</p>
          <h3>{{ item.requirement }}</h3>
          <p style="width: 100%;" v-for="e in item.child_2" :key="e.id">{{ e.title }}</p>
          <h3>{{ item.recruitment }}</h3>
          <p style="text-align: center;">{{ item.headcount }}人</p>
          <h3>{{ item.location }}</h3>
          <div style="width:100%;text-align: left;">
            <span v-for="(e,index) in item.locationList" :key="e.id"
              style="font-size: 2.8rem;">{{ index == (item.locationList.length-1) ? e.title:e.title+'、'}}</span>
          </div>
          <h3>联系方式</h3>
           <p>{{item.Email}}</p>
          <a class="p_1" :href="email">投递简历</a>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from '../components/foot.vue'
import headers from '../components/header.vue'
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { reactive, toRefs, watch } from 'vue'
export default {
  components: { foot, headers, move_foot, move_header },
  computed: {
    icons: function () {
      return () => {

      }
    }
  },
  setup() {

    const state = reactive({
      id: 1,
      dataId: 0,
      isIcon: 'Plus',
      email: 'mailto:shuyu.zhu@shine-optics.com',
      jobDate: [
        { id: 1, name: '重庆' },
        { id: 2, name: '江西' },
        { id: 3, name: '深圳' },
        { id: 4, name: '印度' },
      ],
      engineerList: [
        { id: 1,Email:'shuyu.zhu@shine-optics.com',name: '产品经理', requirement: '任职要求', recruitment: '招聘人数', headcount: 3, location: "工作地点", child_1: [{ id: 1, title: "1.根据公司的发展战略与规划，负责主导公司医疗，AIOT、车联网等智能影像，镜头模组硬件产品设计；" }, { id: 2, title: "2.包括但不限于用户/客户需求、市场调研、竞品分析，产品规划、设计产品功能等工作；" }, { id: 3, title: "3.调研、选型与整合合适的产品供应商，协调硬件研发，推进与外部资源合作的产品开发、测试、试产和量产进程；" }, { id: 4, title: "4.把控和推动产品量产相关的供应链、品质管理环节，解决量产中的问题；" }, { id: 5, title: "5.对市场需求和业务需求进行调研分析，并完成产品设计、流程设计和原型设计，同时输出专利交底文档；" }, { id: 6, title: "6.定期对竞品进行行业跟踪分析，及时掌握行业动态，提出建设性的产品创意提案；" }, { id: 7, title: "7.收集用户意见，持续优化产品用户体验，制定产品改进迭代路线。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业，英语能力良好；" }, { id: 2, title: "2.五年以上车载/医疗影像/智能物联网/智能穿戴/笔电等产品其中的任意一种产品项目经验，主导过相关产品PRD设计，实际参与过产品的全生命周期管理；" }, { id: 3, title: "3.综合素质能力优秀，如沟通协调能力、创新能力、数据分析能力、逻辑思维能力、抗压能力等。" }], locationList: [{ id: 1, title: '重庆' }, { id: 2, title: '深圳' }, { id: 3, title: '江西' }] },
        { id: 2,Email:'shuyu.zhu@shine-optics.com',name: 'tuning工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1、负责手机/车载摄像头3A/ISP效果的调试和优化； " }, { id: 2, title: "2、针对camera sensor的成像效果进行优化。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子、计算机、控制类相关专业毕业；" }, { id: 2, title: "2.具备图像分析能力，能够根据图片分析出影响图像质量的因素；" }, { id: 3, title: "3.了解Qualcomm，MTK 3A算法与 ISP，熟练使用3A调试工具；" }, { id: 4, title: '4. 一年以上手机/车载摄像头3A效果调试经验；' }, { id: 5, title: '5.有较丰富的摄影经验。' }], locationList: [{ id: 1, title: '重庆渝北' }, { id: 2, title: '深圳龙华' }] },
        { id: 3,Email:'shuyu.zhu@shine-optics.com',name: 'layout工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1.CCM的PCB Layout，与板厂的资料对接，审核，确认，封样承认 " }, { id: 2, title: "2.客户所需PCB相关文件的准备" }, { id: 3, title: '3. 厂内及客户端相关FPC技术问题解决' }, { id: 4, title: "4. 贴片图/拼板图等制程文件制作。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业" }, { id: 2, title: "2.熟练运用orCAD、PADS、CAM350等设计软件和Office办公软件，Auto CAD,Allegro的初级应用；" }, { id: 3, title: "3.三年以上电子/摄像头/车载产品Layout工作经验；" }, { id: 4, title: '4.有画过多层板，RF板，HDI（高密度）的工作经历。' }, { id: 5, title: "5.具有良好的沟通表达能力。" }], locationList: [{ id: 1, title: '重庆大足' }, { id: 2, title: '江西新余' }] },
        { id: 4,Email:'shuyu.zhu@shine-optics.com',name: '项目管理', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.主导新项目进行评审' }, { id: 2, title: '2.负责项目样品制作的进度管理' }, { id: 3, title: "3.负责样品、试产的物料生产及出货进度，保证出货达成" }, { id: 4, title: "4.负责组织项目进阶评审" }, { id: 5, title: '5.负责项目技术资料、文件资料的归档' }, { id: 6, title: "6.主导对项目团队成员进行绩效考核" }], child_2: [{ id: 1, title: '1.本科学历，电子、机械、自动化等专业；' }, { id: 2, title: '2.消费类电子行业PM工作经验2年以上；' }, { id: 3, title: "3.擅长跨部门的资源协调与问题推进" }, { id: 4, title: '4.对项目管理、工厂管理有成熟的体系认知，擅长流程改善' }, { id: 5, title: '5.熟悉车载摄像头/笔记本摄像头/电视外挂摄像头/手机摄像头等项目经验者优先考虑' }], locationList: [{ id: 1, title: "重庆" }] },
        { id: 5,Email:'shuyu.zhu@shine-optics.com',name: 'MES开发工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: '1.依据MES业务人员提供MES模块功能说明书进行需求评审、模块开发、接口开发、报表开发等；' }, { id: 2, title: '2.根据公司信息化系统需求，规划和管理公司数据库系统，保障公司数据库运行稳定高效。' }, { id: 3, title: '3.对MES系统新开发的功能进行测试及异常处理。' }], child_2: [{ id: 1, title: '1.本科及以上学历，软件工程等相关专业；' }, { id: 2, title: "2.MES项目开发、团队建设等方面的知识培训；" }, { id: 3, title: "3.同等职位3年以上的MES项目开发经验较强的沟通能力、抗压能力、执行能力；" }, { id: 4, title: "4.熟练掌握C#  C/C++；或者熟练掌握ORACLE、SQLSERVER、HANA其一；" }, { id: 5, title: "5.熟练掌握HTML、JAVASCRIPT；熟练掌握MES模块结构、MES表间关系；或者熟练掌握Eclipse、Visual Studio、Visual C++、Code::Blocks其一。" }], locationList: [{ id: 1, title: "重庆大足" }] },
        { id: 6,Email:'shuyu.zhu@shine-optics.com',name: 'NPI工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: '1.根据产品结构和要求，设计制造工艺流程；' }, { id: 2, title: '2.统筹治工具的设计、验证；' }, { id: 3, title: '3.对制造过程的各站别的站别治工具、参数进行验证；' }, { id: 4, title: "4.分析不良品，输出不良品分析报告；" }], child_2: [{ id: 1, title: '本科及以上学历，LED/LCD直显、背光产品行业，NPI/PE相关工作经验2年以上' }], locationList: [{ id: 1, title: "重庆大足" }] },
        { id: 7,Email:'shuyu.zhu@shine-optics.com',name: '电子工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.同客户交流项目电路、控制线路等相关技术信息 ' }, { id: 2, title: "2.根据客户要求，评估电性风险评估报告。" }, { id: 3, title: '3.列出物料清单，填写零部件单价和用量；' }, { id: 4, title: '4.设计测试板，协助TE实现测试试方法；' }], child_2: [{ id: 1, title: '本科及以上学历，LED/LCD直显、背光产品行业，电子设计相关工作经验2年以上，熟练使用PADS软件。' }], locationList: [{ id: 1, title: "重庆大足" }] },
        { id: 8,Email:'shuyu.zhu@shine-optics.com',name: '光学工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.负责光学加工设备系统光路设计，光学零部件的选型；' }, { id: 2, title: "2.负责专用光学镜头的开发；" }, { id: 3, title: '3.完成公司研发任务，工程任务中激光部分和光学部分的设计工作；' }, { id: 4, title: '4.激光光学装配，检验平台设计，激光镜头的结构设计；' }, { id: 5, title: '5.所负责工作的技术咨询、相关技术资料编辑。' }], child_2: [{ id: 1, title: '1.本科及以上学历，光学类相关专业，英语四级，两年以上摄像头光学设计工作经验；' }, { id: 2, title: "2.熟悉激光器设计、光学零部件设计、光路设计、光学仪表应用等；" }, { id: 3, title: "3.了解机械设计、电气设计，能完成成套设备的系统设计；" }, { id: 4, title: "4.工作有责任心，具有团队协作精神和敬业精神" }], locationList: [{ id: 1, title: "重庆大足" }] },
        // {id:4,name:'项目管理',requirement:'任职要求',recruitment:'招聘人数',headcount:2,location:"工作地点",child_1:[{id:1,title:''}],child_2:[{id:1,title:''}],locationList:[{id:1,title:""}]},

      ]
    })
    watch(() => state.isIcon, (count, prevCount) => {

    })
    let methods = {
      pBtn(id) {
        state.id = id
        state.dataId = 0
        if (id == 1) {
          state.email = 'mailto:shuyu.zhu@shine-optics.com'
          state.engineerList = [
            { id: 1,Email:'shuyu.zhu@shine-optics.com', name: '产品经理', requirement: '任职要求', recruitment: '招聘人数', headcount: 3, location: "工作地点", child_1: [{ id: 1, title: "1.根据公司的发展战略与规划，负责主导公司医疗，AIOT、车联网等智能影像，镜头模组硬件产品设计；" }, { id: 2, title: "2.包括但不限于用户/客户需求、市场调研、竞品分析，产品规划、设计产品功能等工作；" }, { id: 3, title: "3.调研、选型与整合合适的产品供应商，协调硬件研发，推进与外部资源合作的产品开发、测试、试产和量产进程；" }, { id: 4, title: "4.把控和推动产品量产相关的供应链、品质管理环节，解决量产中的问题；" }, { id: 5, title: "5.对市场需求和业务需求进行调研分析，并完成产品设计、流程设计和原型设计，同时输出专利交底文档；" }, { id: 6, title: "6.定期对竞品进行行业跟踪分析，及时掌握行业动态，提出建设性的产品创意提案；" }, { id: 7, title: "7.收集用户意见，持续优化产品用户体验，制定产品改进迭代路线。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业，英语能力良好；" }, { id: 2, title: "2.五年以上车载/医疗影像/智能物联网/智能穿戴/笔电等产品其中的任意一种产品项目经验，主导过相关产品PRD设计，实际参与过产品的全生命周期管理；" }, { id: 3, title: "3.综合素质能力优秀，如沟通协调能力、创新能力、数据分析能力、逻辑思维能力、抗压能力等。" }], locationList: [{ id: 1, title: '重庆' }, { id: 2, title: '深圳' }, { id: 3, title: '江西' }] },
            { id: 2,Email:'shuyu.zhu@shine-optics.com', name: 'tuning工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1、负责手机/车载摄像头3A/ISP效果的调试和优化； " }, { id: 2, title: "2、针对camera sensor的成像效果进行优化。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子、计算机、控制类相关专业毕业；" }, { id: 2, title: "2.具备图像分析能力，能够根据图片分析出影响图像质量的因素；" }, { id: 3, title: "3.了解Qualcomm，MTK 3A算法与 ISP，熟练使用3A调试工具；" }, { id: 4, title: '4. 一年以上手机/车载摄像头3A效果调试经验；' }, { id: 5, title: '5.有较丰富的摄影经验。' }], locationList: [{ id: 1, title: '重庆渝北' }, { id: 2, title: '深圳龙华' }] },
            { id: 3,Email:'shuyu.zhu@shine-optics.com', name: 'layout工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1.CCM的PCB Layout，与板厂的资料对接，审核，确认，封样承认 " }, { id: 2, title: "2.客户所需PCB相关文件的准备" }, { id: 3, title: '3. 厂内及客户端相关FPC技术问题解决' }, { id: 4, title: "4. 贴片图/拼板图等制程文件制作。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业" }, { id: 2, title: "2.熟练运用orCAD、PADS、CAM350等设计软件和Office办公软件，Auto CAD,Allegro的初级应用；" }, { id: 3, title: "3.三年以上电子/摄像头/车载产品Layout工作经验；" }, { id: 4, title: '4.有画过多层板，RF板，HDI（高密度）的工作经历。' }, { id: 5, title: "5.具有良好的沟通表达能力。" }], locationList: [{ id: 1, title: '重庆大足' }, { id: 2, title: '江西新余' }] },
            { id: 4,Email:'shuyu.zhu@shine-optics.com', name: '项目管理', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.主导新项目进行评审' }, { id: 2, title: '2.负责项目样品制作的进度管理' }, { id: 3, title: "3.负责样品、试产的物料生产及出货进度，保证出货达成" }, { id: 4, title: "4.负责组织项目进阶评审" }, { id: 5, title: '5.负责项目技术资料、文件资料的归档' }, { id: 6, title: "6.主导对项目团队成员进行绩效考核" }], child_2: [{ id: 1, title: '1.本科学历，电子、机械、自动化等专业；' }, { id: 2, title: '2.消费类电子行业PM工作经验2年以上；' }, { id: 3, title: "3.擅长跨部门的资源协调与问题推进" }, { id: 4, title: '4.对项目管理、工厂管理有成熟的体系认知，擅长流程改善' }, { id: 5, title: '5.熟悉车载摄像头/笔记本摄像头/电视外挂摄像头/手机摄像头等项目经验者优先考虑' }], locationList: [{ id: 1, title: "重庆" }] },
            { id: 5,Email:'shuyu.zhu@shine-optics.com', name: 'MES开发工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: '1.依据MES业务人员提供MES模块功能说明书进行需求评审、模块开发、接口开发、报表开发等；' }, { id: 2, title: '2.根据公司信息化系统需求，规划和管理公司数据库系统，保障公司数据库运行稳定高效。' }, { id: 3, title: '3.对MES系统新开发的功能进行测试及异常处理。' }], child_2: [{ id: 1, title: '1.本科及以上学历，软件工程等相关专业；' }, { id: 2, title: "2.MES项目开发、团队建设等方面的知识培训；" }, { id: 3, title: "3.同等职位3年以上的MES项目开发经验较强的沟通能力、抗压能力、执行能力；" }, { id: 4, title: "4.熟练掌握C#  C/C++；或者熟练掌握ORACLE、SQLSERVER、HANA其一；" }, { id: 5, title: "5.熟练掌握HTML、JAVASCRIPT；熟练掌握MES模块结构、MES表间关系；或者熟练掌握Eclipse、Visual Studio、Visual C++、Code::Blocks其一。" }], locationList: [{ id: 1, title: "重庆大足" }] },
            { id: 6,Email:'shuyu.zhu@shine-optics.com', name: 'NPI工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: '1.根据产品结构和要求，设计制造工艺流程；' }, { id: 2, title: '2.统筹治工具的设计、验证；' }, { id: 3, title: '3.对制造过程的各站别的站别治工具、参数进行验证；' }, { id: 4, title: "4.分析不良品，输出不良品分析报告；" }], child_2: [{ id: 1, title: '本科及以上学历，LED/LCD直显、背光产品行业，NPI/PE相关工作经验2年以上' }], locationList: [{ id: 1, title: "重庆大足" }] },
            { id: 7,Email:'shuyu.zhu@shine-optics.com', name: '电子工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.同客户交流项目电路、控制线路等相关技术信息 ' }, { id: 2, title: "2.根据客户要求，评估电性风险评估报告。" }, { id: 3, title: '3.列出物料清单，填写零部件单价和用量；' }, { id: 4, title: '4.设计测试板，协助TE实现测试试方法；' }], child_2: [{ id: 1, title: '本科及以上学历，LED/LCD直显、背光产品行业，电子设计相关工作经验2年以上，熟练使用PADS软件。' }], locationList: [{ id: 1, title: "重庆大足" }] },
            { id: 8,Email:'shuyu.zhu@shine-optics.com', name: '光学工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.负责光学加工设备系统光路设计，光学零部件的选型；' }, { id: 2, title: "2.负责专用光学镜头的开发；" }, { id: 3, title: '3.完成公司研发任务，工程任务中激光部分和光学部分的设计工作；' }, { id: 4, title: '4.激光光学装配，检验平台设计，激光镜头的结构设计；' }, { id: 5, title: '5.所负责工作的技术咨询、相关技术资料编辑。' }], child_2: [{ id: 1, title: '1.本科及以上学历，光学类相关专业，英语四级，两年以上摄像头光学设计工作经验；' }, { id: 2, title: "2.熟悉激光器设计、光学零部件设计、光路设计、光学仪表应用等；" }, { id: 3, title: "3.了解机械设计、电气设计，能完成成套设备的系统设计；" }, { id: 4, title: "4.工作有责任心，具有团队协作精神和敬业精神" }], locationList: [{ id: 1, title: "重庆大足" }] },

          ]
        } else if (id == 2) {
          state.email = 'mailto:ruifang.liu@shine-optics.com'
          state.engineerList = [
            { id: 1,Email:'ruifang.liu@shine-optics.com', name: '产品经理', requirement: '任职要求', recruitment: '招聘人数', headcount: 3, location: "工作地点", child_1: [{ id: 1, title: "1.根据公司的发展战略与规划，负责主导公司医疗，AIOT、车联网等智能影像，镜头模组硬件产品设计；" }, { id: 2, title: "2.包括但不限于用户/客户需求、市场调研、竞品分析，产品规划、设计产品功能等工作；" }, { id: 3, title: "3.调研、选型与整合合适的产品供应商，协调硬件研发，推进与外部资源合作的产品开发、测试、试产和量产进程；" }, { id: 4, title: "4.把控和推动产品量产相关的供应链、品质管理环节，解决量产中的问题；" }, { id: 5, title: "5.对市场需求和业务需求进行调研分析，并完成产品设计、流程设计和原型设计，同时输出专利交底文档；" }, { id: 6, title: "6.定期对竞品进行行业跟踪分析，及时掌握行业动态，提出建设性的产品创意提案；" }, { id: 7, title: "7.收集用户意见，持续优化产品用户体验，制定产品改进迭代路线。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业，英语能力良好；" }, { id: 2, title: "2.五年以上车载/医疗影像/智能物联网/智能穿戴/笔电等产品其中的任意一种产品项目经验，主导过相关产品PRD设计，实际参与过产品的全生命周期管理；" }, { id: 3, title: "3.综合素质能力优秀，如沟通协调能力、创新能力、数据分析能力、逻辑思维能力、抗压能力等。" }], locationList: [{ id: 1, title: '重庆' }, { id: 2, title: '深圳' }, { id: 3, title: '江西' }] },
            { id: 2,Email:'ruifang.liu@shine-optics.com', name: '产品工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.完成新产品可行性研究、方案设计，及产品风险分析；' }, { id: 2, title: "2.根据设计任务书及法规输入资料，负责新产品硬件设计工作；" }, { id: 3, title: "3.编写与硬件相关的技术文档；" }, { id: 4, title: "4.负责组织、制定新产品关键元器件的技术指标和检验标准、采购清单、工艺作业指导书等技术文件的编制；" }, { id: 5, title: "5.负责新品试验工作及为相关部门提供技术支持；" }, { id: 6, title: '6.领导安排的其他工作。' }], child_2: [{ id: 1, title: '1.本科及以上学历，通信、电子工程、及其相关专业，熟练掌握Orcad、PADS软件；' }, { id: 2, title: '2.能识别各种通用的元器件，掌握其基本的参数以及应用；' }, { id: 3, title: "3.懂得模拟电路和数字电路设计，具有单片机、ARM开发技能；" }, { id: 4, title: '4.有摄像头模组厂以及非手机模组工作经验两年以上者优先，' }, { id: 5, title: "5.熟练操作电子仪器仪表及工具，精通硬件调试，动手能力强，能焊接调试电路板" }, { id: 6, title: '6. 思维开阔、爱学习钻研，性格开朗、沟通能力强，具有团队协作及集体荣誉感！' }], locationList: [{ id: 1, title: "江西新余" }] },
            { id: 3,Email:'ruifang.liu@shine-optics.com', name: 'layout工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1.CCM的PCB Layout，与板厂的资料对接，审核，确认，封样承认 " }, { id: 2, title: "2.客户所需PCB相关文件的准备" }, { id: 3, title: '3. 厂内及客户端相关FPC技术问题解决' }, { id: 4, title: "4. 贴片图/拼板图等制程文件制作。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业" }, { id: 2, title: "2.熟练运用orCAD、PADS、CAM350等设计软件和Office办公软件，Auto CAD,Allegro的初级应用；" }, { id: 3, title: "3.三年以上电子/摄像头/车载产品Layout工作经验；" }, { id: 4, title: '4.有画过多层板，RF板，HDI（高密度）的工作经历。' }, { id: 5, title: "5.具有良好的沟通表达能力。" }], locationList: [{ id: 1, title: '重庆大足' }, { id: 2, title: '江西新余' }] },
          ]
        } else if (id == 3) {
          state.email = 'mailto:deqiang.zhang@shine-optics.com'
          state.engineerList = [
            { id: 1,Email:'deqiang.zhang@shine-optics.com', name: '产品经理', requirement: '任职要求', recruitment: '招聘人数', headcount: 3, location: "工作地点", child_1: [{ id: 1, title: "1.根据公司的发展战略与规划，负责主导公司医疗，AIOT、车联网等智能影像，镜头模组硬件产品设计；" }, { id: 2, title: "2.包括但不限于用户/客户需求、市场调研、竞品分析，产品规划、设计产品功能等工作；" }, { id: 3, title: "3.调研、选型与整合合适的产品供应商，协调硬件研发，推进与外部资源合作的产品开发、测试、试产和量产进程；" }, { id: 4, title: "4.把控和推动产品量产相关的供应链、品质管理环节，解决量产中的问题；" }, { id: 5, title: "5.对市场需求和业务需求进行调研分析，并完成产品设计、流程设计和原型设计，同时输出专利交底文档；" }, { id: 6, title: "6.定期对竞品进行行业跟踪分析，及时掌握行业动态，提出建设性的产品创意提案；" }, { id: 7, title: "7.收集用户意见，持续优化产品用户体验，制定产品改进迭代路线。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子相关专业，英语能力良好；" }, { id: 2, title: "2.五年以上车载/医疗影像/智能物联网/智能穿戴/笔电等产品其中的任意一种产品项目经验，主导过相关产品PRD设计，实际参与过产品的全生命周期管理；" }, { id: 3, title: "3.综合素质能力优秀，如沟通协调能力、创新能力、数据分析能力、逻辑思维能力、抗压能力等。" }], locationList: [{ id: 1, title: '重庆' }, { id: 2, title: '深圳' }, { id: 3, title: '江西' }] },
            { id: 2,Email:'deqiang.zhang@shine-optics.com', name: 'tuning工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 2, location: "工作地点", child_1: [{ id: 1, title: "1、负责手机/车载摄像头3A/ISP效果的调试和优化； " }, { id: 2, title: "2、针对camera sensor的成像效果进行优化。" }], child_2: [{ id: 1, title: "1.本科及以上学历，电子、计算机、控制类相关专业毕业；" }, { id: 2, title: "2.具备图像分析能力，能够根据图片分析出影响图像质量的因素；" }, { id: 3, title: "3.了解Qualcomm，MTK 3A算法与 ISP，熟练使用3A调试工具；" }, { id: 4, title: '4. 一年以上手机/车载摄像头3A效果调试经验；' }, { id: 5, title: '5.有较丰富的摄影经验。' }], locationList: [{ id: 1, title: '重庆渝北' }, { id: 2, title: '深圳龙华' }] },
            { id: 3,Email:'deqiang.zhang@shine-optics.com', name: 'camera驱动开发工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 6, location: "工作地点", child_1: [{ id: 1, title: '1. 相机相关的硬件驱动开发和调试；' }, { id: 2, title: "2. 相机稳定性调试。" }], child_2: [{ id: 1, title: '1. 本科及以上学历，计算机、软件、电子等相关专业；' }, { id: 2, title: "2. 熟悉C/C++编程开发，调试技能；" }, { id: 3, title: "3. 熟悉Linux/Android 的设备驱动kernel /Driver 的相关技术；" }, { id: 4, title: "4. 熟悉camera基础知识，有手机平台相关的开发经验；" }, { id: 5, title: '5. 负责手机Sensor的点亮、拍照性能与功耗优化等；' }, { id: 6, title: "6. 有Camera 驱动开发、调试等经验优先考虑。" }], locationList: [{ id: 1, title: "深圳龙华" }] },


          ]
        } else if (id == 4) {
          state.engineerList = [
            { id: 1,Email:'shuyu.zhu@shine-optics.com', name: 'IE工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.负责生产现场标准工时、标准作业、价值流、生产布局、作业优化、管理流程优化等改善工作；' }, { id: 2, title: "2.参与项目效率改善、品质改善方案的调研、制定，负责相关专案的执行；" }, { id: 3, title: '3.负责生产管理制度、流程的编制、优化与实施推进；' }, { id: 4, title: "4.负责生产、运营相关改善课题的研究，参与方案的设计与执行；" }, { id: 5, title: '5.服从领导临时性安排。' }], child_2: [{ id: 1, title: '1.本科学历，工业工程相关专业；' }, { id: 2, title: '2.至少一年机加工行业经验；' }, { id: 3, title: "3.有精益生产、现场效率改善、生产运营实际工作经验；" }, { id: 4, title: '4.有着一定的项目规划、组织管理、沟通协调能力；' }, { id: 5, title: "5.熟悉应用Office/CAD等办公软件，对自动化、设备、工装夹具有一定了解。" }, { id: 6, title: "6.英语听说读写能力良好，能接受派外工作的条件" }], locationList: [{ id: 1, title: "印度" }] },
            { id: 2,Email:'shuyu.zhu@shine-optics.com', name: 'EOL PE工程师', requirement: '任职要求', recruitment: '招聘人数', headcount: 1, location: "工作地点", child_1: [{ id: 1, title: '1.制程优化及产品良率/自动化设备直通率改善' }, { id: 2, title: "2.作业标准化管理，文件编写及制定" }, { id: 3, title: "3.作业人员培训及考核认证" }, { id: 4, title: '4.产线异常分析/客诉分析，重工方案处理' }, { id: 5, title: "5.制程优化" }, { id: 6, title: "6.新材料、新工艺开发与导入"},{ id: 7, title: "7.专案改善/6sigma项目改善推动" }, { id: 8, title: '8.协调各部门资源，利于日常工作展开' }, { id: 9, title: "9.客户稽核事项及配合完成事项。" }], child_2: [{ id: 1, title: '1.本科学历，机电/机械相关专业；' }, { id: 2, title: '2.两年以上摄像头模组行业工作经验；' }, { id: 3, title: '3.了解光学摄像头模组结构，熟悉前后段制程；' }, { id: 4, title: "4.对SFR MTF  烧录 污点 外观等不良有较强分析能力；" }, { id: 5, title: "5.英语听说读写能力良好，能接受派外工作的条件" }], locationList: [{ id: 1, title: "印度" }] },
          ],
          state.email = 'mailto:shuyu.zhu@shine-optics.com'
        }
      },
      IdBtn(id) {
        state.dataId = id
      },
      SemiSelectBtn() {
        state.dataId = 0
      }
    }
    return {
      ...toRefs(state), ...methods
    }
  }
}

</script>

<style scoped lang="less">
.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

.p_1 {
  display: block;
  margin: 0 auto;
  text-indent: 0 !important;
  font-size: 20px;
  text-align: center;
  width: 170px;
  height: 42px;
  border: 1px solid #ccc;
  line-height: 42px;
  cursor: pointer;
}

.p_1:hover {
  color: #fff !important;
  background-color: #ef3e2e;
}

.p_Btn {
  color: #fff !important;
  background-color: #ef3e2e;
}

.box {
  width: 100%;

  .content {
    display: flex;
    margin: 0 auto;
    width: 70%;
    min-height: 100px;
    max-width: 1920px;

    .region {

      margin: 10px 0;
      flex: 1;

      p {
        text-indent: 1em;
      }

      p:hover {
        color: #ef3e2e
      }
    }

    .job {
      margin: 10px 0;
      margin-left: 30px;
      flex: 6;

      .info {
        position: relative;
        cursor: pointer;
        min-height: 80px;
        border: 1px solid #ccc;
        margin: 10px 0;
        text-align: center;
        line-height: 80px;

        .info_1 {
          text-align: left;
          font-weight: 500;
          margin-left: 30px;
        }

        .info_2 {
          font-size: 30px;
          position: absolute;
          top: 0;
          right: 30px;
          text-align: right;
          color: #ef3e2e;
          cursor: pointer;
        }

        .info_3 {
          padding-top: 10px;
          height: auto;
          background-color: #eee;
          line-height: 20px;
          padding-bottom: 16px;
          cursor: default;
          div {
            margin-left: 30px;
          }

          h3 {
            text-align: left;
            margin-left: 30px;
          }

          .p_1 {
            text-align: center;
            margin-top: 30px;
            margin-left: 30px;
            text-decoration: none;
            color: black;
          }

          p {
            margin: 0;
            padding: 0;
            text-align: left;
            margin-left: 30px;
            min-height: 40px;
            line-height: 35px;
          }
        }
      }
    }
  }
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: 60autovh;
  max-height: 600px;
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;



    .content_center {
      padding: 30px;
      text-align: center;

      p {
        display: inline-block;
        margin-left: 50px;
        font-size: 70px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }

      .p_move {
        border-bottom: 8px solid #ef3e2e;
      }

      .info {
        width: 100%;
        height: auto;
        border: 1px solid #ccc;
        margin: 50px 0;
        min-height: 250px;
        cursor: pointer;

        .span_1,
        .span_2 {
          font-size: 60px;
          line-height: 250px;
        }

        .span_1 {
          float: left;
          margin-left: 100px;
        }

        .span_2 {
          float: right;
          font-size: 90px;
          margin-right: 100px;
          cursor: pointer;
          color: #ef3e2e;
        }

        .span_3 {
          background-color: #eee;
          margin-top: 230px;
          text-align: left;
          padding:  0 30px;
          box-sizing: border-box;
          padding-bottom: 30px;
          cursor: default;

          h3 {
            font-size: 60px;
            padding-top: 80px;
            text-align: center;
          }

          p {
            font-size: 45px;
            margin: 10px 0;
            text-align: left;
            font-weight: 400;
          }

          a {
            text-align: center;
            // margin-top: 30px;
            // margin-left: 30px;
            font-size: 60px;
            width: 450px;
            height: 120px;
            line-height: 120px;
            border: 1px solid #ccc;
            text-decoration: none;
            color: black;
            margin-top: 10px;
            padding-bottom: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>